import React, { useContext, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Editor from '../../../components/admin/editor'
import AdminLayout from '../../../components/layouts/admin'
import { saveBlogPost } from '../../../utils/requests'
import { LoadingContext } from '../../../context/loading-context'
import { AuthContext } from '../../../context/auth-context'
import { Input, TextArea, Label } from '../../../components/common/input'
import { AdminAuthor } from '../../../components/common/typography'
import {
  AdminDashboardButtonWrapper,
  AdminDashboardActionButton,
} from '../../../components/common/button'

const Wrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;
  margin: 0 auto;
  padding: 60px 30% 0px 30%;
  height:100vh;
  overflow-y:auto;
`

const NewBlogPage = () => {
  const { setLoading } = useContext(LoadingContext)
  const { state: authState } = useContext(AuthContext)
  const [state, setState] = useState({
    title: '',
    description: '',
    topic: '',
  })
  const editorRef = useRef(null)

  const handleSave = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      const editorData = await editorRef.current.save()
      const response = await saveBlogPost({
        ...state,
        data: editorData,
        author: authState.user?.name,
        type: 'career',
        active: true,
      })
      setLoading(false)

      if (response.success) {
        navigate(`/admin/career/${response.data.body.id}`)
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <AdminLayout>
      <form onSubmit={handleSave}>
        <AdminDashboardButtonWrapper>
          <AdminDashboardActionButton onClick={handleSave}>
            Create
          </AdminDashboardActionButton>
        </AdminDashboardButtonWrapper>
        <Wrapper>
          <Label>
            Title
            <Input name="title" onBlur={handleChange} />
          </Label>

          <Label>
            Description
            <TextArea name="description" onBlur={handleChange} />
          </Label>
          <Label>
            Topic
            <Input name="topic" onBlur={handleChange} />
          </Label>
          <Label>
            Author: <AdminAuthor>{authState.user?.name}</AdminAuthor>
          </Label>
          <div>
            <Label>Content</Label>
            <Editor editorRef={editorRef} />
          </div>
        </Wrapper>
      </form>
    </AdminLayout>
  )
}

export default NewBlogPage
